// The list is in inverted chronological order.
// First items are the latest.

module.exports = [
  {
    title: 'August 6, 2022',
    message: `Welcome to Cryptoodles`,
    link: 'https://www.facebook.com/watch/?v=1251531378341913'
  }
];
